<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="12">
          <AddResume />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import AddResume from "@/components/AddResume";
export default {
  name: "AddResumePage",
  components: { PageHeader, AddResume },
  data: () => ({
    headercontent: {
      title: "Add Resume",
      addlink: "/dashboard/training/add",
      downloadlink: true,
    },
  }),
};
</script>

<style lang="scss" scoped></style>
